// src/index.js

import "resize-observer-polyfill"; // Sobrescribe el ResizeObserver nativo
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline, ThemeProvider } from "@mui/material";
import darkTheme from "./theme";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={darkTheme}>
    <CssBaseline /> {/* Resetea los estilos base para respetar el tema */}
    <App />
  </ThemeProvider>
);

serviceWorkerRegistration.register();

reportWebVitals();
