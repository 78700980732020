// UnderConstructionPage.js
import React from "react";
import { Container, Typography, Box } from "@mui/material";

function UnderConstructionPage() {
  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center">
        <img src="/jaguarlogo.png" alt="Logo" />
        <Typography variant="h3" component="h1" gutterBottom>
          Página en construcción
        </Typography>
        <Typography variant="subtitle1">
          Estamos trabajando para traerte algo increíble. ¡Vuelve pronto!
        </Typography>
      </Box>
    </Container>
  );
}

export default UnderConstructionPage;
