// src/App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import theme from "./theme";
import Home from "./pages/Home";
import UnderConstructionPage from "./components/UnderConstructionPage";
import ProjectDetails from "./pages/Project/ProjectDetails";
// import "./App.css";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ overflow: "hidden" }}>
        {" "}
        {/* Aplica overflow hidden globalmente */}
        {/* <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/under-construction"
              element={<UnderConstructionPage />}
            />
            <Route path="/projects/:projectId" element={<ProjectDetails />} />
          </Routes>
        </Router> */}
        <UnderConstructionPage />
      </Box>
    </ThemeProvider>
  );
}

export default App;
