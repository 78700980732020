// src/theme.js

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1F1F1F", // Gris oscuro similar al fondo de la imagen
      contrastText: "#FFFFFF", // Blanco para contrastar con el fondo oscuro
    },
    secondary: {
      main: "#B3B3B3", // Gris claro para detalles o acentos
      contrastText: "#1F1F1F", // Gris oscuro como color de texto
    },
    background: {
      default: "#1A1A1A", // Fondo gris oscuro
      paper: "#333333", // Fondo ligeramente más claro para tarjetas y elementos destacados
    },
    text: {
      primary: "#FFFFFF", // Texto en blanco para resaltar sobre fondos oscuros
      secondary: "#B3B3B3", // Texto en gris claro para menos énfasis
    },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
    h1: {
      fontWeight: 700,
      color: "#FFFFFF", // Color blanco para títulos importantes
    },
    h2: {
      fontWeight: 600,
      color: "#B3B3B3", // Gris claro para subtítulos
    },
    body1: {
      color: "#FFFFFF", // Texto principal en blanco
    },
    body2: {
      color: "#B3B3B3", // Texto secundario en gris claro
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "md", // Limita el ancho máximo para un diseño centrado
      },
      styleOverrides: {
        root: {
          paddingLeft: "16px",
          paddingRight: "16px",
          "@media (min-width:600px)": {
            paddingLeft: "24px",
            paddingRight: "24px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          padding: "10px 20px",
          backgroundColor: "#B3B3B3",
          color: "#1F1F1F",
          "&:hover": {
            backgroundColor: "#FFFFFF",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%", // Los TextFields ocupan todo el ancho
          marginBottom: "16px", // Espaciado debajo de cada TextField
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#B3B3B3", // Color del borde en estado normal
            },
            "&:hover fieldset": {
              borderColor: "#FFFFFF", // Color del borde en hover
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FFFFFF", // Color del borde al enfocarse
            },
          },
          "& .MuiInputLabel-root": {
            color: "#B3B3B3", // Color de la etiqueta (label)
          },
          "& .MuiInputBase-input": {
            color: "#FFFFFF", // Color del texto en el campo de entrada
          },
        },
      },
    },
  },
});

export default theme;
